<script lang="ts" setup>
import { isObjectEmpty } from '@/js/utils/common';
import AdminPanel from '@app/components/parts/topBar/adminPanel.vue';
import AdminToggler from '@app/components/parts/topBar/adminToggler.vue';
import MenuToggler from '@app/components/parts/topBar/menuToggler.vue';
import MobileMenu from '@app/components/parts/topBar/mobileMenu.vue';
import Notifications from '@app/components/parts/topBar/notifications.vue';
import Search, { TopBarSearchLangInterface } from '@app/components/parts/topBar/search.vue';
import SearchToggler from '@app/components/parts/topBar/searchToggler.vue';
import Settings from '@app/components/parts/topBar/settings.vue';
import Sla from '@app/components/parts/topBar/sla.vue';
import Tabs from '@app/components/parts/topBar/tabs.vue';
import User from '@app/components/parts/topBar/user.vue';
import { useMediaQuery } from '@vueuse/core';
import { ref, onMounted, onUnmounted } from 'vue';
import NotificationsList from '@ui/components/notificationsList.vue';
import { useDebounceFn } from '@vueuse/core';
import { tv } from 'tailwind-variants';

export interface TopBarInterface {
    menu?: object;
    search?: {
        url: string;
    };
    sla?: object;
    notifications?: {
        countUrl?: string;
        listUrl?: string;
        allUrl?: string;
        seenUrl?: string;
        seenAllUrl?: string;
    };
    settings?: object;
    user?: object;
    admin?: object;
    lang?: {
        search?: TopBarSearchLangInterface;
    };
    isDev?: boolean;
    systemMessages?: {
        text: string;
        theme: 'error' | 'warning' | 'info' | 'success';
    }[];
}

const props = withDefaults(defineProps<TopBarInterface>(), {
    search: () => {
        return {
            url: '',
        };
    },
    menu: () => {
        return {};
    },
    sla: () => {
        return {};
    },
    notifications: () => {
        return {};
    },
    settings: () => {
        return {};
    },
    user: () => {
        return {};
    },
    admin: () => {
        return {};
    },
    lang: () => {
        return {
            search: {},
        };
    },
    isDev: false,
    systemMessages: () => {
        return [];
    },
});

const isMobile = useMediaQuery('(max-width: 1023px)');
const openMobileMenu = ref(false);
const openMobileSearch = ref(false);
const openAdminPanel = ref(false);

const topBarElement = ref<HTMLElement | null>(null);
const visibleHeight = ref(0);

const updateCssVariable = (value) => {
    const root = document.documentElement;
    root.style.setProperty('--topbar-visible-height', `${value}px`);
};

let observer: IntersectionObserver;

const computeVisibleHeight = () => {
    if (topBarElement.value) {
        const rect = topBarElement.value.getBoundingClientRect();
        const visiblePortion = Math.max(0, Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0));
        visibleHeight.value = visiblePortion;
        updateCssVariable(visibleHeight.value);
    }
};

const debouncedComputeVisibleHeight = useDebounceFn(computeVisibleHeight, 100);

onMounted(() => {
    if (topBarElement.value) {
        observer = new IntersectionObserver(
            (entries) => {
                for (const entry of entries) {
                    if (entry.isIntersecting) {
                        debouncedComputeVisibleHeight();
                    } else {
                        visibleHeight.value = 0;
                    }
                }
            },
            {
                root: null,
                threshold: 0.01,
            },
        );

        observer.observe(topBarElement.value);
        window.addEventListener('scroll', debouncedComputeVisibleHeight);
    }
});

onUnmounted(() => {
    if (observer && topBarElement.value) {
        observer.unobserve(topBarElement.value);
    }
    window.removeEventListener('scroll', debouncedComputeVisibleHeight);
});
function toggleMobileMenu() {
    openMobileMenu.value = !openMobileMenu.value;
}
function hideMobileMenu() {
    openMobileMenu.value = false;
}
function hideMobileSearch() {
    openMobileSearch.value = false;
}
function showMobileSearch() {
    openMobileSearch.value = true;
    hideMobileMenu();
}
function toggleAdmin() {
    openAdminPanel.value = !openAdminPanel.value;
    hideMobileMenu();
}
function onNotification(value) {
    if (value === true) {
        hideMobileMenu();
    }
}
const systemMessageClass = tv({
    base: 'pattern-diagonal-lines pattern-opacity-80 pattern-size-16 min-h-2 py-1 text-center font-sans text-sm font-medium',
    variants: {
        theme: {
            error: 'pattern-bg-red-200 pattern-red-300 text-red-800',
            warning: 'pattern-bg-yellow-200 pattern-yellow-300 text-yellow-800',
            info: 'pattern-bg-brand-200 pattern-brand-300 text-brand-800',
            success: 'pattern-bg-green-200 pattern-green-300 text-green-800',
        },
    },
});
</script>
<template>
    <div
        ref="topBarElement"
        class="relative z-[200] flex w-full flex-col"
    >
        <AdminPanel
            v-if="!isObjectEmpty(props.admin)"
            :admin="props.admin"
            :open="openAdminPanel"
        ></AdminPanel>
        <div
            v-if="props.isDev"
            class="pattern-diagonal-lines pattern-bg-yellow-500 pattern-yellow-600 pattern-opacity-100 pattern-size-16 min-h-2 text-center font-sans text-xs font-bold uppercase text-white"
        ></div>
        <template v-if="props.systemMessages.length > 0">
            <div
                v-for="(message, key) in props.systemMessages"
                :key="key"
                :class="systemMessageClass({ theme: message.theme })"
            >
                {{ message.text }}
            </div>
        </template>
        <div
            class="relative flex h-14 items-stretch bg-gray-800 px-2 text-lg text-gray-400 lg:h-16 lg:space-x-4 lg:pl-4 lg:pr-2.5 2xl:space-x-8"
        >
            <MenuToggler
                v-if="isMobile"
                :open="openMobileMenu"
                @update:is-open="toggleMobileMenu"
            ></MenuToggler>

            <a
                href="/zadavatel"
                class="group z-20 flex flex-shrink-0 items-center justify-center no-underline"
            >
                <img
                    class="h-8 w-8"
                    src="@/images/recruitis.png"
                >
            </a>

            <Tabs
                v-if="!isMobile"
                :menu="props.menu"
            ></Tabs>
            <div
                class="absolute left-0 right-0 top-0 z-20 flex h-14 flex-grow items-center bg-gray-800 px-2 lg:relative lg:h-auto lg:translate-y-0 lg:opacity-100"
                :class="
                    [
                        openMobileSearch
                            ? 'opacity-100 transition-opacity duration-150 ease-in-out'
                            : 'pointer-events-none opacity-0 transition-opacity duration-150 ease-in-out lg:pointer-events-auto',
                    ].join(' ')
                "
            >
                <Search
                    :is-mobile="isMobile"
                    :url="props.search.url"
                    :open-mobile-search="openMobileSearch"
                    :lang="props.lang.search"
                    @hide-mobile-search="hideMobileSearch"
                ></Search>
            </div>
            <div class="ml-auto flex w-auto flex-row items-center space-x-1 sm:space-x-4 2xl:space-x-6">
                <Sla
                    v-if="!isObjectEmpty(props.sla) && !isMobile"
                    :data="props.sla"
                    :is-mobile="isMobile"
                    @show="hideMobileMenu"
                ></Sla>
                <SearchToggler
                    v-if="isMobile"
                    @show="showMobileSearch"
                ></SearchToggler>
                <Notifications
                    v-if="!isObjectEmpty(props.notifications)"
                    :is-mobile="isMobile"
                    :count-url="props.notifications.countUrl"
                    :list-url="props.notifications.listUrl"
                    :all-url="props.notifications.allUrl"
                    :seen-url="props.notifications.seenUrl"
                    :seen-all-url="props.notifications.seenAllUrl"
                    :limit="50"
                    @toggle="onNotification"
                ></Notifications>
                <Settings
                    v-if="!isObjectEmpty(props.settings)"
                    :is-mobile="isMobile"
                    :menu="props.settings"
                    @show="hideMobileMenu"
                ></Settings>
                <AdminToggler
                    v-if="!isObjectEmpty(props.admin)"
                    :is-mobile="isMobile"
                    :admin="props.admin"
                    :open-admin-panel="openAdminPanel"
                    @show-admin-panel="toggleAdmin"
                ></AdminToggler>
                <User
                    v-if="!isObjectEmpty(props.user)"
                    :is-mobile="isMobile"
                    :user="props.user"
                    @show="hideMobileMenu"
                ></User>
            </div>
        </div>
        <MobileMenu
            v-if="isMobile"
            :open="openMobileMenu"
            :sla="props.sla"
            :menu="props.menu"
        ></MobileMenu>
        <svg
            viewBox="0 0 100 100"
            class="absolute bottom-0 right-0 h-2 w-2 translate-y-full -rotate-90 text-gray-800 lg:h-4 lg:w-4"
        >
            <path
                d="M 0,100 L 100,100 L 100,0 Q 100,100 0,100 Z"
                fill="currentColor"
            />
        </svg>
        <svg
            viewBox="0 0 100 100"
            class="absolute bottom-0 left-0 h-2 w-2 translate-y-full rotate-180 text-gray-800 lg:h-4 lg:w-4"
        >
            <path
                d="M 0,100 L 100,100 L 100,0 Q 100,100 0,100 Z"
                fill="currentColor"
            />
        </svg>
        <NotificationsList></NotificationsList>
    </div>
</template>
